#Line_1,
#Line_2,
#Line_3,
#Line_4,
#Line_5,
#Line_6 {
  animation: wave ease 1s infinite;
}

#Line_1 {
  animation-delay: 0s;
}

#Line_5 {
  animation-delay: 0.25s;
}

#Line_4 {
  animation-delay: 0.5s;
}

#Line_2 {
  animation-delay: 0.25s;
}

#Line_3 {
  animation-delay: 0.5s;
}

@keyframes wave {
  0% {
    transform: scaleY(1);
    transform-origin: 50% 50%;
  }

  50% {
    transform: scaleY(0.7);
    transform-origin: 50% 50%;
  }

  100% {
    transform: scaleY(1);
    transform-origin: 50% 50%;
  }
}
