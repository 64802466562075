.default {
  @apply bg-deep-orange-100 text-deep-orange-800;
}

.gray {
  @apply bg-gray-100 text-gray-800;
}

.warning {
  @apply bg-white text-deep-orange-600;
}

.rose100 {
  @apply bg-rose-100 text-rose-500;
}

.rose400 {
  @apply bg-rose-400 text-white;
}

.orange200 {
  @apply bg-deep-orange-200 text-deep-orange-900;
}

.orange400 {
  @apply bg-deep-orange-600 text-white;
}

.orange600 {
  @apply bg-deep-orange-600 text-white;
}

.outline {
  @apply bg-gray-800 text-deep-orange-400;
}
