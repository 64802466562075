.brand-picker {
  @apply bg-slate-200/50;
  &:hover {
    @apply cursor-pointer border border-slate-200/80 bg-slate-100 transition-colors duration-150 ease-in-out hover:bg-slate-200 #{!important};

    .upload-icon,
    p {
      @apply text-slate-700 transition-colors duration-150 ease-in-out #{!important};
    }
  }
}
