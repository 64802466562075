.scroll0 {
  animation: scroll 100s linear infinite;
}

.scroll1 {
  animation: scroll 160s linear infinite;
}

.scroll2 {
  animation: scroll 80s linear infinite;
}

.scroll3 {
  animation: scroll 165s linear infinite;
}

.scroll4 {
  animation: scroll 120s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
}
