.bn-block-content[data-is-empty-and-focused] .bn-inline-content:has(> .ProseMirror-trailingBreak)::before {
  content: 'Type here...' !important;
}

.bn-block-content[data-is-empty-and-focused][data-content-type='heading']
  .bn-inline-content:has(> .ProseMirror-trailingBreak:only-child)::before {
  content: 'Type here...' !important;
}

.bn-block-content[data-content-type='heading'] .bn-inline-content:has(> .ProseMirror-trailingBreak:only-child)::before {
  content: '' !important;
}

.bn-block {
  line-height: 1.8;
  padding-left: 30px;
  padding-right: 30px;
}

.ProseMirror {
  padding: 0px;
}

.bn-default-styles {
  h1 {
    font-size: 1.5rem !important;
    font-weight: 600 !important;
  }

  h2 {
    font-size: 1.25rem !important;
    font-weight: 600 !important;
  }

  h3 {
    font-size: 1rem !important;
    font-weight: 600 !important;
  }
}
