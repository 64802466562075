.btn-group-filled {
  @apply divide-slate-400 bg-black text-white;
}
.btn-group-btn-fillled {
  @apply hover:bg-deep-orange-600;
}
.btn-group-outline {
  @apply divide-slate-200 !border border-slate-200 text-black;
}
.btn-group-btn-outline {
  @apply outline-none hover:border-deep-orange-50 hover:bg-deep-orange-50 hover:text-deep-orange-600 disabled:bg-transparent disabled:text-slate-400;
}
.btn-group-list {
  @apply divide-slate-300 !border border-slate-300 bg-white text-black;
}
.btn-group-btn-list {
  @apply outline-none hover:border-deep-orange-50 hover:bg-deep-orange-50 hover:text-deep-orange-600 disabled:bg-transparent disabled:text-slate-400;
}
.btn-group-warn {
  @apply divide-rose-200 !border border-rose-200 text-rose-500;
}
.btn-group-btn-warn {
  @apply outline-none hover:border-rose-50 hover:bg-rose-50 hover:text-rose-600 focus:ring-rose-500;
}
