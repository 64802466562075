.colors-container {
  @apply bg-slate-200/50;
  &:hover {
    @apply cursor-pointer border border-slate-200/80 bg-slate-100 hover:bg-slate-200 transition-colors duration-150 ease-in-out #{!important};

    .colors-icon,
    p {
      @apply text-slate-700 #{!important};
    }
  }
}
