.content-lab-root {
  @tailwind base;
}
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-feature-settings: 'zero' 1, 'cv09' 1, 'cv10' 1;
    @apply bg-white text-slate-900;
  }
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* // use css modules */
button,
input,
select,
textarea {
  border-style: solid;
}

.Toastify__toast {
  @apply !rounded-lg !border !border-slate-200 !text-sm !leading-tight !text-slate-900 !shadow-lg;
}
