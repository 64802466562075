.upload-container {
  @apply border-dashed #{!important};
  &:hover {
    @apply cursor-pointer border-deep-orange bg-deep-orange-50/50 transition-all duration-150 ease-in-out #{!important};

    .upload-icon,
    p {
      @apply text-deep-orange-600 #{!important};
    }
  }
}
