.word::before {
  content: '';
  width: calc(100% + 4.5px);
  height: calc(100% + 4px);
  z-index: -1;
  position: absolute;
  left: 0px;
  top: -2px;
}

.active-word {
  background-color: #ff4200;
  color: white;
  border-radius: 0.25rem;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

[data-viewport-type='element'] {
  @apply max-w-prose;
}
