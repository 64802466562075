.btn-filled {
  @apply bg-black text-white hover:bg-deep-orange hover:text-white disabled:cursor-not-allowed disabled:border-slate-200 disabled:bg-slate-50 disabled:text-slate-400 disabled:text-slate-500 disabled:hover:bg-slate-200 !important;
}
.btn-outline {
  @apply border border-slate-200 text-black outline-none hover:border-deep-orange-50 hover:bg-deep-orange-50 hover:text-deep-orange disabled:cursor-not-allowed disabled:bg-slate-200 disabled:text-slate-400 disabled:hover:border-transparent disabled:hover:bg-slate-200 aria-checked:bg-deep-orange-50  aria-checked:text-deep-orange !important;
}
.btn-warn {
  @apply border border-rose-200 text-rose-500 outline-none hover:border-rose-50 hover:bg-rose-50 hover:text-rose-600 focus:ring-rose-500 !important;
}
.btn-text {
  @apply border border-transparent text-black hover:text-deep-orange-600 disabled:text-slate-400 !important;
}
.btn-list {
  @apply border border-slate-200 bg-white text-black outline-none hover:border-deep-orange-50 hover:bg-deep-orange-50 hover:text-deep-orange-600 !important;
}
.btn-disabled {
  @apply cursor-not-allowed bg-slate-200 text-slate-400 !important;
}
.btn-success {
  @apply cursor-default bg-green-700 text-white disabled:cursor-not-allowed disabled:bg-green-100 disabled:text-green-700 disabled:text-slate-400 disabled:hover:border-transparent !important;
}
.btn-active {
  @apply border-black bg-white text-black outline-none hover:border-deep-orange-50 hover:bg-deep-orange-50 hover:text-deep-orange-600 !important;
}

.btn-checkbox {
  @apply text-black outline-none hover:bg-slate-100 disabled:cursor-not-allowed disabled:bg-slate-200 disabled:text-slate-400 disabled:hover:border-transparent disabled:hover:bg-slate-200 aria-checked:bg-slate-100 !important;
}
